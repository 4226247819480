.logo {
  &__link {
    display: flex;
    align-items: center;
  }

  &__image {
    width: auto;
    height: 70px;
  }
}