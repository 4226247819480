@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.home-page-about {
  padding: 100px 30px;

  &__container {
    @extend %home-page-container;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    @media (min-width: 960px) {
      flex-direction: row;
    }
  }

  &__image-box {
    position: relative;
    padding-bottom: 20px;
  }

  &__image {
    width: 100%;
    border-radius: 10px;
  }

  &__image-text {
    position: absolute;
    bottom: -40px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    font-size: 18px;
    font-weight: 700;
    color: $white;
    background-image: url(../icons/about.png);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;

    @media (min-width: 570px) {
      font-size: 26px;
    }
  }

  &__content {
    box-sizing: border-box;
    width: 100%;

    @media (min-width: 960px) {
      width: 50%;
    }
  }

  &__sub-title {
    margin-bottom: 20px;
    @extend %sub-title;
  }

  &__title {
    margin-bottom: 20px;
    @extend %title-h1;
  }

  &__text {
    margin-bottom: 20px;
    @extend %gray-text;
  }
}