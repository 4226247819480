@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.my-profile {
  @extend %profile-block-shadow;

  &__title {
    margin-bottom: 20px;
    @extend %title-h2;
  }

  &__box {
    padding: 30px 0;
  }

  &__sub-title {
    font-size: 20px;
    margin-bottom: 30px;
  }

  &__text {
    margin-bottom: 20px;
    font-family: $Nunito;
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__input-label {
    display: block;
    margin-bottom: 10px;
    font-family: $Nunito;
  }

  &__input-label-password {
    display: block;
    width: 200px;
    margin-left: 20px;
    padding-top: 10px;
    font-size: 12px;
    text-align: center;
    color: $orange;

    @media (min-width: 420px) {
      width: 250px;
    }
  }

  &__input {
    display: block;
    width: 200px;
    height: 34px;
    padding: 0 20px;
    font-family: $Nunito;
    font-size: 14px;
    border: 2px solid $gray;
    border-radius: 24px;
    outline: none;

    @media (min-width: 420px) {
      width: 250px;
      font-size: 16px;
    }

    &--error {
      border-color: $red;
    }
  }

  &__input-error {
    margin: 10px 0 0 20px;
    font-size: 12px;
    color: $red;
  }

  &__button {
    @extend %button;
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  &__message {
    font-family: $Nunito;
  }
}