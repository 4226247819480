@import './styles/variables.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Comfortaa", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $red;
  }
}

.body-mobile {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  padding: 0;
  font-size: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

a,
button {
  &:active {
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: transparent;
    -ms-tap-highlight-color: transparent;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-moz-search-cancel-button {
  -moz-appearance: none;
}

input[type="search"]::-ms-clear {
  display: none;
}