@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.moderation {
  padding: 30px;

  &__container {
    @extend %container;
  }

  &__loading {
    @extend %centering;
    text-align: center;
  }

  &__page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__left-side {
    width: 20%;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media (min-width: 768px) {
      flex-direction: column;
    }
  }

  &__menu-button {
    position: relative;
    width: max-content;
    font-family: $Comfortaa;
    font-size: 14px;
    text-align: left;
    line-height: 30px;

    @media (min-width: 380px) {
      font-size: 16px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1.5px;
      width: 100%;
      background-color: black;
      transform: scaleX(0);
    }

    &--active {
      &::after {
        display: block;
        transform: scaleX(1);
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (min-width: 1000px) {
      width: 80%;
    }
  }

  &__post {
    padding: 20px;
    box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.1);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 500px) {
      flex-direction: row;
    }
  }

  &__image {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    object-fit: cover;

    @media (min-width: 500px) {
      margin: 0;
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__item {
    margin-bottom: 10px;
    font-family: $Nunito;
  }

  &__link {
    display: block;
    margin-bottom: 10px;
    font-family: $Nunito;
    color: $red;
  }

  &__text {
    margin-bottom: 20px;
    font-family: $Nunito;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 360px) {
      flex-direction: row;
    }
  }

  &__button {
    @extend %button;
  }
}