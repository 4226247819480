@import './styles/variables.scss';

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

  &__main {
    flex-grow: 1;
  }

  &__arrow-up {
    position: fixed;
    bottom: 40px;
    right: 0;
    width: 70px;
    height: 56px;
    background-color: $red;
    border-radius: 30px 0 0 30px;
    transform: translateX(70px);
    transition: transform $smoothTime;
    z-index: 20;

    &--active {
      transform: translateX(0);
    }

    &::after {
      position: absolute;
      content: "";
      top: 8px;
      left: 8px;
      width: 40px;
      height: 40px;
      background-color: $white;
      background-image: url(./icons/arrow-up.svg);
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center;
      border-radius: 20px;
    }
  }
}