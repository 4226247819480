@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.search-page {
  padding: 40px 20px;

  &__container {
    @extend %container;
  }

  &__centering {
    @extend %centering;
  }

  &__header {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 620px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0;
    }
  }

  &__title {
    margin-right: 20px;
    @extend %title-h1;
  }

  &__query {
    margin-bottom: 40px;
    font-family: $Nunito;
    font-size: 18px;
  }

  &__posts-list {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    @media (min-width: 550px) {
      gap: 4%;
    }

    @media (min-width: 870px) {
      gap: 2.66%;
    }
  }
}