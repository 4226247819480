@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.my-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @extend %profile-block-shadow;

  &__title {
    margin-bottom: 20px;
    @extend %title-h2;
  }

  &__button {
    @extend %button;
  }

  &__loading {
    @extend %centering;
  }

  &__post {
    position: relative;
    padding: 20px;
    border: 2px solid $gray;
    border-radius: 10px;
  }

  &__status {
    margin-bottom: 10px;
    color: $gray;
    font-weight: bold;

    &--active {
      color: $green;
    }

    &--rejected {
      color: $red;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 600px) {
      flex-direction: row;
    }
  }

  &__image-container {
    position: relative;
    width: 80%;
    margin: 0 auto 10px;
    padding-top: 80%;

    @media (min-width: 600px) {
      width: max-content;
      margin: 0;
      padding: 0;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (min-width: 600px) {
      position: static;
      width: 150px;
      height: 150px;
    }
  }

  &__item-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
  }

  &__text {
    margin-bottom: 20px;
    font-family: $Nunito;
  }

  &__buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__deleting-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &__message {
    padding-top: 15px;
  }

  &__loading-message {
    text-align: center;
  }
}