@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.authorization {
  @extend %container;
  display: flex;
  justify-content: space-around;
  flex-direction: column-reverse;
  gap: 50px;
  padding: 20px 30px;

  @media (min-width: 1000px) {
    flex-direction: row-reverse;
  }

  &__content {
    width: 100%;
    padding: 0;

    @media (min-width: 1000px) {
      width: 40%;
      padding: 40px;
    }
  }

  &__content-subtitle {
    margin-bottom: 20px;
    @extend %sub-title;
  }

  &__content-title {
    margin-bottom: 30px;
    @extend %title-h1;
  }

  &__content-text {
    margin-bottom: 10px;
    @extend %gray-text;
  }

  &__forms {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
  }

  &__switcher {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }

  &__button {
    width: max-content;
    height: 40px;
    padding: 0 30px;
    font-family: $Comfortaa;
    font-size: 16px;
    color: $black;
    background-color: $white;
    border: 2px solid $gray;
    border-radius: 24px;
    transition: border-color $smoothTime;

    @media (min-width: 768px) {
      &:hover {
        border-color: $red;
      }
    }

    &--active {
      color: $white;
      background-color: $red;
      border-color: $red;
    }

    &--refresh {
      height: 30px;
      font-size: 14px;
    }
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    @extend %block-shadow;

    @media (min-width: 540px) {
      padding: 40px 100px;
    }
  }

  &__input-label {
    display: inline-block;
    width: 240px;
    margin-left: 20px;
    margin-bottom: 6px;
    font-size: 14px;

    &--refresh {
      width: 240px;
      margin: 0 0 20px;
      text-align: center;
      line-height: 22px;

      @media (min-width: 420px) {
        width: 390px;
        margin: 0 -50px 20px;
      }
    }
  }

  &__message {
    max-width: 290px;
    text-align: center;
  }

  &__input-label-password {
    display: block;
    width: 240px;
    margin-left: 20px;
    padding-top: 10px;
    font-size: 12px;
    text-align: center;
    color: $orange;
  }

  &__input {
    display: block;
    width: 200px;
    height: 34px;
    padding: 0 20px;
    font-family: $Nunito;
    font-size: 14px;
    border: 2px solid $gray;
    border-radius: 24px;
    outline: none;

    @media (min-width: 420px) {
      width: 250px;
      font-size: 16px;
    }

    &--error {
      border-color: $red;
    }
  }

  &__input-error {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 12px;
    color: $red;
  }

  &__loading {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}