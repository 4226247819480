@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.post-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__form-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
  }

  &__input-title {
    font-size: 18px;
    font-weight: 700;

    &--contacts {
      display: inline-block;
      width: 100px;
      margin-right: 20px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__input-note {
    max-width: 600px;
    font-family: $Nunito;
    font-size: 14px;
    color: $darkGray;
  }

  &__label {
    display: block;
    margin-left: 10px;
    font-family: $Nunito;
    cursor: pointer;
  }

  &__input {
    visibility: hidden;
  }

  &__input:checked+.post-form__custom-radio-button {
    border: 2px solid $orange;
  }

  &__input:checked+span:after {
    opacity: 1;
  }

  &__custom-radio-button {
    position: relative;
    left: -10px;
    top: 4px;
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid gray;
    border-radius: 50%;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 9px;
      height: 9px;
      background: $orange;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity $smoothTime;
    }

    &--error {
      border-color: $red;
    }
  }

  &__input-field {
    display: inline-block;
    height: 30px;
    padding: 0 20px;
    font-family: $Nunito;
    font-size: 16px;
    border: 2px solid $gray;
    border-radius: 24px;
    outline: none;

    &--contacts {
      width: 50%;
      max-width: 300px;
      margin-bottom: 5px;
    }

    &::placeholder {
      font-size: 14px;
      color: $gray;
    }

    &--error {
      border-color: $red;
    }
  }

  &__input-error {
    font-size: 12px;
    color: $red;
  }

  &__textarea {
    height: 100px;
    padding: 10px 20px;
    font-family: $Nunito;
    font-size: 16px;
    border: 2px solid $gray;
    outline: none;
    resize: none;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray;
    }

    &--error {
      border-color: $red;
    }
  }

  &__image-box {
    display: flex;
    align-items: center;
    height: 75px;
  }

  &__image-label {
    @extend %button;
  }

  &__thumbnails {
    display: inline-block;
    width: 75px;
    height: 75px;
    margin-left: 20px;
    object-fit: cover;
    border-radius: 5px;
  }

  &__input-image {
    display: none;
  }

  &__button-submit {
    @extend %button;
  }

  &__custom-checkbox {
    position: relative;
    left: -10px;
    top: 4px;
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid gray;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 9px;
      height: 9px;
      background: $orange;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity $smoothTime;
    }

    &--error {
      border-color: $red;
    }
  }

  &__input:checked+.post-form__custom-checkbox {
    border: 2px solid $orange;
  }

  &__error-message {
    padding-top: 20px;
    font-weight: bold;
  }

  &__loading {
    display: flex;
    justify-content: center;
  }
}