@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.add-new-post {
  @extend %profile-block-shadow;

  &__title {
    margin-bottom: 40px;
    @extend %title-h2;
  }
}