@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.footer {
  background-color: $darkBlue;

  &__container {
    @extend %home-page-container;
    padding: 40px 30px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
      align-items: start;
      gap: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__link {
    position: relative;
    font-size: 14px;
    color: $white;
    transition: color $smoothTime;

    &:hover {
      color: $orange;
    }

    &::before {
      position: absolute;
      top: -5px;
      left: -35px;
      content: "";
      width: 25px;
      height: 25px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--email {
      &::before {
        background-image: url(../icons/email.svg);
      }
    }

    &--telegram {
      &::before {
        background-image: url(../icons/telegram.svg);
      }
    }

    &--instagram {
      &::before {
        background-image: url(../icons/instagram.svg);
      }
    }
  }

  &__logo-image {
    width: auto;
    height: 80px;
  }
}