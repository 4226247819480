@import '../styles/variables.scss';

.search {
  position: relative;
  width: 100%;

  @media (min-width: 620px) {
    max-width: 300px;
  }

  &__input {
    width: 100%;
    height: 40px;
    padding-left: 16px;
    padding-right: 44px;
    font-family: $Nunito;
    font-size: 16px;
    border: 2px solid $orange;
    border-radius: 30px;
    outline: none;

    &::placeholder {
      font-size: 16px;
    }
  }

  &__button {
    position: absolute;
    top: 1px;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    background-color: $red;

    &::after {
      content: "";
      width: 22px;
      height: 22px;
      background-color: transparent;
      background-image: url(../icons/search.svg);
      background-size: contain;
    }
  }
}