@import '../styles/variables.scss';

.pagination {
  display: flex;
  gap: 10px;

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    font-size: 14px;
    font-weight: 700;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
    border: 2px solid $orange;
    border-radius: 32px;

    @media (min-width: 768px) {
      transition: all $smoothTime;

      &:hover {
        color: $white;
        background-color: $red;
        border-color: $red;
      }
    }

    &--left {
      background-image: url(../icons/left.svg);

      @media (min-width: 768px) {
        &:hover {
          background-image: url(../icons/left-white.svg);
        }
      }
    }

    &--right {
      background-image: url(../icons/right.svg);

      @media (min-width: 768px) {
        &:hover {
          background-image: url(../icons/right-white.svg);
        }
      }
    }

    &--active {
      color: $white;
      background-color: $red;
      border-color: $red;
      pointer-events: none;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__link-text {
    transform: translateY(1px);
  }
}