@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.faq {
  &__container {
    @extend %home-page-container;
    padding: 100px 30px;
    text-align: center;
  }

  &__sub-title {
    margin-bottom: 20px;
    @extend %sub-title;
  }

  &__title {
    margin-bottom: 50px;
    @extend %title-h1;
  }

  &__content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  &__qa {
    width: 96%;

    @media (min-width: 880px) {
      width: 70%;
    }
  }

  &__question {
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 72px;
    margin-bottom: 20px;
    padding: 0 50px 0 26px;
    font-family: $Comfortaa;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    background-color: transparent;
    border-radius: 24px;
    border: 2px solid $gray;
    transition: all $smoothTime;

    @media (min-width: 400px) {
      height: 48px;
    }

    &:hover {
      border: 2px solid $red;
    }

    &::after {
      position: absolute;
      content: "+";
      top: 0;
      right: 16px;
      font-size: 34px;
      font-weight: 300;
      line-height: 72px;
      color: $red;
      transition: all $smoothTime;

      @media (min-width: 400px) {
        line-height: 48px;
      }
    }

    &--active {
      color: $white;
      background-color: $red;
      border: 2px solid $red;
      transition: all $smoothTime;

      &::after {
        content: "+";
        color: $white;
        font-size: 34px;
        font-weight: 300;
        line-height: 72px;
        transform: rotate(45deg);
        transition: all $smoothTime;

        @media (min-width: 400px) {
          line-height: 48px;
        }
      }
    }
  }

  &__answer {
    @extend %gray-text;
    width: 90%;
    max-height: 0;
    margin: 0 auto;
    text-align: start;
    opacity: 0;
    overflow: hidden;
    transition: all $smoothTime;

    &--active {
      max-height: 200px;
      margin: 0 auto 20px;
      opacity: 1;
    }
  }
}