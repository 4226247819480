@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.cards-page {
  padding: 20px 20px;
  min-height: 450px;

  &__container {
    @extend %container;
  }

  &__header {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    margin-bottom: 40px;

    @media (min-width: 620px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0;
    }
  }

  &__title {
    padding-right: 0;
    @extend %title-h1;

    @media (min-width: 620px) {
      padding-right: 20px;
    }
  }

  &__breadcrumbs {
    margin-bottom: 20px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 800px) {
      flex-direction: row;
      gap: 40px;
    }
  }

  &__sidebar {
    display: none;

    @media (min-width: 800px) {
      display: block;
      min-width: 180px;
      width: 15%;
    }
  }

  &__category-title {
    font-size: 20px;
  }

  &__category-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__category-list-item {
    font-weight: 700;
    transition: color $smoothTime;

    &:hover {
      color: $orange;
    }

    &--active {
      color: $orange;
    }
  }

  &__category-mobile-button {
    width: 100%;
    height: 40px;
    font-family: $Comfortaa;
    color: $white;
    border-radius: 30px;
    background-color: $red;

    @media (min-width: 800px) {
      display: none;
    }
  }

  &__category-mobile {
    @extend %block-shadow;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 0;
    padding: 0 30px;
    opacity: 0;
    overflow: hidden;
    transition: all $smoothTime;

    @media (min-width: 800px) {
      display: none;
    }

    &--active {
      max-height: 250px;
      padding: 30px;
      opacity: 1;
    }
  }

  &__posts-list-wrapper {
    width: 100%;
  }

  &__posts-list {
    display: flex;
    gap: 0;
    flex-wrap: wrap;
    width: 100%;
    min-height: 200px;
    margin-bottom: 40px;

    @media (min-width: 550px) {
      gap: 4%;
    }

    @media (min-width: 870px) {
      gap: 2%;
    }
  }

  &__centering {
    @extend %centering;
    width: 100%;
    text-align: center;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
}