$white: #ffffff;
$black: #000000;
$gray: #c8c8c8;
$darkGray: #616161;
$red: #f75529;
$blue: #33b8e4;
$darkBlue: #222328;
$green: #44d693;
$orange: #ffac00;

$smoothTime: 0.3s;

$Comfortaa: "Comfortaa", Arial, Helvetica, sans-serif;
$Nunito: "Nunito", Arial, Helvetica, sans-serif;
$Amatic: "Amatic SC", Arial, Helvetica, sans-serif;