@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.post-page {
  padding: 20px 30px;

  &__container {
    max-width: 1300px;
    margin: 0 auto;
  }

  &__breadcrumbs {
    margin-bottom: 20px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (min-width: 768px) {
      flex-direction: row;
      ;
    }

    @media (min-width: 1100px) {
      gap: 100px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;

    @media (min-width: 768px) {
      width: 50%;
      height: 50%;
    }

    @media (min-width: 1100px) {
      width: 500px;
      height: 500px;
    }
  }

  &__content {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 1100px) {
      width: max-content;
    }
  }

  &__title {
    margin-bottom: 30px;
    @extend %title-h1;
  }

  &__text {
    font-family: $Nunito;
    font-size: 18px;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 16px;
  }

  &__location {
    margin-bottom: 30px;
    font-family: $Nunito;
    font-size: 18px;
  }

  &__delivery-title {
    margin-bottom: 20px;
  }

  &__delivery-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: disc;
  }

  &__delivery-item {
    margin-left: 30px;
    font-family: $Nunito;
  }

  &__contacts {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__contact-item {
    @extend %button;
  }

  &__centering {
    @extend %centering;
  }
}