@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.useful-resources {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 30px;
  font-family: $Nunito;

  &__title {
    @extend %title-h2;
  }

  &__link-box {
    @extend %block-shadow;
  }

  &__link {
    display: inline-block;
    margin-bottom: 10px;
    font-family: $Comfortaa;
    font-size: 22px;
    font-weight: bold;
    transition: color $smoothTime;

    &:hover {
      color: $red;
    }
  }
}