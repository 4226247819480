@import '../styles/variables.scss';
@import '../styles/placeholders.scss';

.home-page-categories {
  padding: 100px 30px 50px;
  background-image: url(../icons/bg.webp);
  background-size: cover;

  &__container {
    @extend %home-page-container;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 28px;
    line-height: 46px;
    font-weight: 700;

    @media (min-width: 1190px) {
      width: 320px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    @media (min-width: 1190px) {
      width: 770px;
      padding: 0;
    }
  }

  &__item {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-left: 80px;
    padding-bottom: 50px;

    @media (min-width: 768px) {
      width: 50%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      width: 90px;
      height: 59px;
      background-size: contain;
    }

    &--free {
      &::before {
        background-image: url(../icons/free.png);
      }
    }

    &--services {
      &::before {
        background-image: url(../icons/help.png);
      }
    }

    &--sos {
      &::before {
        background-image: url(../icons/sos.png);
      }
    }

    &--donate {
      &::before {
        background-image: url(../icons/donate.png);
      }
    }
  }

  &__item-title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
  }

  &__item-text {
    width: 90%;
    @extend %gray-text;
  }
}