@import '../styles/variables.scss';

.be-volunteer {
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }

  &__category {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 490px;
    padding: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: 1200px) {
      width: 33.33%;
    }

    &--free {
      background-image: url(../icons/free.webp);
    }

    &--donate {
      background-image: url(../icons/donate.webp);
    }

    &--services {
      background-image: url(../icons/services.webp);
    }

    &:hover .be-volunteer__button {
      background-color: $orange;
    }

    &:hover .be-volunteer__button::after {
      color: $orange;
      transform: rotate(180deg)
    }
  }

  &__title {
    margin-bottom: 30px;
    font-family: $Amatic;
    font-size: 50px;
    font-weight: bold;
    color: $white;
  }

  &__text {
    color: $white;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
  }

  &__button {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 110px;
    height: 56px;
    background-color: $red;
    border-radius: 0 30px 30px 0;
    transition: all $smoothTime;

    &:hover {
      background-color: $orange;
    }

    &::after {
      position: absolute;
      content: "+";
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: 42px;
      line-height: 42px;
      color: $red;
      background-color: $white;
      border-radius: 20px;
      transition: all $smoothTime;
    }

    &:hover::after {
      color: $orange;
      transform: rotate(180deg)
    }
  }
}