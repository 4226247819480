@import "../styles/variables.scss";

.bread-crumbs {
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-wrap: wrap;

  &__item {
    &::before {
      display: inline-block;
      content: "";
      width: 12px;
      height: 12px;
      margin: 0 8px;
      background-image: url(../icons/arrow-right.svg);
      background-size: cover;
      transform: translateY(2px);
    }

    &--text {
      color: $gray;
    }
  }
}