@import '../styles/variables.scss';

.post-card {
  &__post {
    box-sizing: border-box;
    flex-shrink: 1;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 16px 16px 0 16px;
    border-radius: 10px;
    border: 1px solid $gray;

    @media (min-width: 768px) {
      transition: all $smoothTime;

      &:hover {
        box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.1);
      }
    }

    @media (min-width: 550px) {
      flex-basis: 48%;
    }

    @media (min-width: 870px) {
      flex-basis: 32%;
    }

    &--search {
      @media (min-width: 870px) {
        flex-basis: 23%;
      }
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    padding-top: 100%;
  }

  &__post-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__post-title {
    margin-bottom: 16px;
    font-size: 16px;
  }

  &__post-location {
    margin-bottom: 16px;
    font-size: 14px;
    color: $gray;

    &::before {
      display: inline-block;
      content: "";
      width: 18px;
      height: 17px;
      margin-right: 6px;
      background-image: url(../icons/location.svg);
      background-size: contain;
      transform: translateY(2px);
    }
  }
}