@import '../styles/variables.scss';

.menu-mobile {
  width: max-content;
  min-height: 100vh;
  padding: 0 40px;
  background-color: $white;
  border-right: 2px solid $gray;
  z-index: 10;

  &__close {
    width: 20px;
    height: 20px;
    margin: 40px 0;
    background-color: $white;
    background-image: url(../icons/close.svg);
    background-size: contain;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 60px;
    font-size: 16px;
    font-weight: 700;
  }

  &__link {
    display: inline-block;
    position: relative;
    line-height: 36px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1.5px;
      width: 100%;
      background-color: $black;
      transform: scaleX(0);
    }

    &--active {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  &__button-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    padding-left: 6px;
    font-size: 16px;
    font-weight: 700;
    line-height: 0;
    border-radius: 50px;
    border: 2px solid $orange;

    &::before {
      content: "+";
      transform: translateY(1px) translateX(-6px);
    }
  }
}