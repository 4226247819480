.loading {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background-image: url(../icons/loading.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: load 2s infinite ease-in-out;
}

@keyframes load {
  from {
    transform: scale(100%);
  }

  50% {
    transform: scale(90%);
  }

  to {
    transform: scale(100%);
  }
}