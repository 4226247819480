@import '../styles/variables.scss';

.slider {
  position: relative;
  width: 100%;
  height: calc(100dvh - 90px);
  background-color: transparent;
  overflow: hidden;
}

.slide {
  position: absolute;
  opacity: 0;
  transition: opacity 2s ease-out;
}

.slide.active {
  position: absolute;
  opacity: 1;
  transition: opacity 1s ease-out;
}

.home-page-slider {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;

  &__image {
    width: 100%;
    min-height: calc(100dvh - 90px);
    object-fit: cover;
    object-position: center;
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100vw;
    height: calc(100dvh - 90px);
    font-weight: bold;
    text-align: center;
    color: $white;
    z-index: 2;
  }

  @keyframes slide-sub-text {
    0% {
      transform: translateY(-25px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__sub-text {
    position: relative;
    font-family: $Amatic;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    animation: slide-sub-text 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 0.6s;

    @media (min-width: 450px) {
      font-size: 40px;
    }

    &::after {
      content: '';
      position: absolute;
      top: -60px;
      left: calc(50% - 98px);
      width: 195px;
      height: 130px;
      background-image: url(../icons/slider.png);
      background-size: contain;
      z-index: -1;
    }
  }

  @keyframes slide-main-text {
    0% {
      transform: translateY(75px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__text-main {
    padding: 0 20px;
    font-size: 40px;
    font-weight: bold;
    animation: slide-main-text 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 0.3s;

    @media (min-width: 600px) {
      font-size: 60px;
    }

    @media (max-height: 500px) {
      font-size: 40px;
    }
  }

  @keyframes scale-map {
    0% {
      transform: scale(1.2);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  &__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100dvw;
    height: calc(100dvh - 90px);
    background-image: url(../icons/map.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    animation: scale-map 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 0.8s;
  }
}