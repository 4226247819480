@import '../styles/variables.scss';

.not-found-page {
  padding: 150px 30px;
  font-family: $Amatic;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  background-image: url(../icons/big_heart.png);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
}