.activation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 150px 30px;

  &__message {
    max-width: 600px;
    text-align: center;
    font-size: 18px;
    line-height: 28px;
  }
}