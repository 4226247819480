@import '../styles/variables.scss';

.header {
  position: relative;
  border-bottom: 2px solid $gray;

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    margin: 0 30px;
  }

  &__nav-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translateX(-100%);
    transition: transform $smoothTime linear;
    z-index: 10;

    &--active {
      transform: translateX(0);
    }
  }

  &__burger-icon {
    display: none;
    width: 30px;
    height: 30px;
    background-color: $white;
    background-image: url(../icons/burger.svg);
    background-size: contain;

    @media (max-width: 1150px) {
      display: block;
    }
  }

  &__link {
    display: block;
    position: relative;
    line-height: 36px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1.5px;
      width: 100%;
      background-color: $black;
      transform: scaleX(0);
      transition: all $smoothTime;
    }

    &:hover {
      &::after {
        display: block;
        transform: scaleX(1);
      }
    }

    &--active {
      &::after {
        display: block;
        transform: scaleX(1);
      }
    }
  }

  &__menu {
    display: flex;
    gap: 40px;
    font-size: 16px;
    font-weight: 700;

    @media (max-width: 1150px) {
      display: none;
    }
  }

  &__button-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    padding-left: 6px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 50px;
    border: 2px solid $orange;
    transition: all $smoothTime;

    &::before {
      content: "+";
      transform: translateY(1px) translateX(-6px);
    }

    &:hover {
      background-color: $red;
      border-color: $red;
      color: $white;
    }

    &:active {
      border-color: $orange;
    }

    @media (max-width: 1150px) {
      display: none;
    }
  }
}